@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi+Ink&display=swap');




:root {

    --primary1-light: #FAF7E0;
    --primary1: #FFEC3F;
    --primary3: #FEE500;
    --primary2-dark1: #1976D2;
    --primary2-dark2: #209BFF;
    --primary2-dark3: #3DBDFF;
    --primary2-light2: #F8F8F8;
    --special-black: #1c1c1c;
    --special-black-light:#363636;
}

.App {
    text-align: center;
    width: 100%;
}

#root{
  width:100%;
  font-family: 'Inter', sans-serif;
  height: 100%;
  color: var(--special-black);
}

.App-link {
    color: #61dafb;
}

/*NAV BAR*/
header {
    all: unset;
}

.nav {
    padding: 10px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: 60px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0.000015px 3px 2px rgba(0, 0, 0, 0.234);
    z-index: 1;
    background-color: var(--primary1);
    position: sticky;
    top: 0;
}
.responsiveNav{
  position:fixed;
  top:0px;
  z-index: 400;
  background:var(--primary1);
  width:100vw;
  height:fit-content;
  transition: ease-in-out 0.5s;
}


#close {
    all: unset;
    z-index: 400;
    width: 40px;
    padding: 5px 10px;
    height: fit-content;
    position: absolute;
    top: 5px;
    right: 10px;
    opacity:0.5;
}

#close:hover {
    color: var(--primary1);
    cursor: pointer;
    opacity:1;
}

.sns-logo {
    display: flex;
    gap: 20px;
    margin: auto;

    padding-top: 50px;
    align-items: center;
    justify-content: center;
}

.sns-logo i{
  color:var(--special-black);
  font-size: 30px;
  opacity: 0.4;
  text-decoration: none;

}

.sns-logo i:hover {
    color:var(--special-black);
    opacity:1
}

.responsiveEl {
    margin-top: 7rem;
    margin-bottom: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
}


.responsiveEl a {
    font-size: 3rem;
    text-decoration: none;
    color: var(--special-black);
    text-transform: uppercase;
    font-weight: 600;
    opacity: 0.6;
}

.responsiveNav a:hover {
    opacity: 1;
}


.navRight {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 200px;
}

.navRight .icon {
    display: none;

}

.nav-sns {
    margin-right: 30px;
    top: 38%;
    right: 20%;
    font-size: 12px;
}

#commission {
    font-size: 12px;
    background-color: var(--primary3);
    padding: 7px 10px;
    border-radius: 10px;
    box-shadow: 1px 2px 14px -7px rgba(0, 0, 0, 0.57);
    -webkit-box-shadow: 1px 2px 10px -7px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 1px 2px 14px -7px rgba(0, 0, 0, 0.57);
    transition: 0.2s ease-in-out;
    opacity: 0.5;
}

#commission:hover {
    opacity: 1;
    transition: 0.2s ease-in-out;
}

#commission a {
    color: var(--special-black);
    transition: 0.1s ease-in-out;

}

#commission a:hover {
    transition: 0.1s ease-in-out;

}

.wepo-logo {
    width: 100px;
    margin-left: 50px;
}

.nav .link {
    text-decoration: none;
    color: var(--special-black);
    opacity: 1;
    transition: 0.2s ease-in;
    padding: 10px;
}

.nav .linkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inputField {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

#demo-radio-buttons-group-label {
    margin: 0px;
    padding: 0px;
    font-size: 1.5rem;
    font-weight: 600;
}

.okform .radioN {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin:auto;
  margin-top: 20px;
}
/*FORM CONTAINER*/

.MuiFormGroup-root {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
}

.okform{
  position:fixed;
  width: 100%;
  height:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  top:0;
  z-index: 10;
  background: rgba(255, 225, 33, 0.98);
}

.okform .inputField{
  display: flex;
  justify-content: center;
  margin:auto;

}

.okform{
  background:'red';
  z-index: 100;
}
/*.form {
    margin: auto;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 80%;
    z-index: 400;
}
*/





.form h2 {
  font-weight: 2rem;
    font-family: 'Reem Kufi Ink', sans-serif;
  width:'80%';
    padding:0 20%;
}

/*HERO ================================================*/

.heroContainer {
    height: 650px;
    padding: 10px 230px 0px 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.heroLeft {
    width: 40%;
    height: 70%;
    display: flex;
    margin: 0px;
    padding: 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
}

.heroLeft .text {
    text-align: left;
    width: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;
    height: auto;
    font-size: 16px;
    line-height: 150%;
  font-weight: 400;
}

#copywrite {
    line-height: 109%;
    font-size: 70px;
    position: absolute;
    margin: 0px;
    padding: 0px;
}
.slider div{
  font-family: 'Reem Kufi Ink', sans-serif;
  font-weight: bold;
  color: white; 
  height:50px;
  margin-bottom:50px ;
  display: flex;
  justify-content:center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  font-size: 3rem;
  background: var(--primary1);
  padding: 4px 10px;

}
.slider{
  height: 50px;
  overflow: hidden;
}
.slide-1{
  animation: slide 10s linear infinite;

}
@keyframes slide {
  0% {margin-top:-150px;}
  3%{margin-top:-100px}
  33%{margin-top: -100px;}
  37% {margin-top:-50px;}
  66% {margin-top:-50px;}
  69% {margin-top:0px;}
  100% {margin-top:0px;}
}

#end {
    font-size: 60px;
    font-weight: bold;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    padding: 0px;
    line-height: 115%;
  font-family: 'Reem Kufi Ink', sans-serif;;

}
.CTA-container{
    display: flex;
    align-items: center;
    align-content: center;
    margin:auto
}
.CTA{
    text-decoration: none;
    margin:auto;
  padding: 0.7rem;
  margin-top: 0.5rem;
  background-color: var(--special-black);
  border-radius: 5px;
  color:white;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  gap:10px;
}

.CTA:hover {
    cursor: pointer;
    background-color: var(--special-black-light);
}

.heroRight {
    width: 40%;
    height: 60%;
    box-sizing: border-box;
    position: relative;
    top: 0%;
}

.heroRight img {
    position: absolute;
    margin: 0px;
    padding: 0px;
    right: 0%;
    top: -50%;
    max-width: 130%;
    height: auto;
}

/*BENEFIT*=================================*/

.divide {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 5rem;
    margin-bottom: 2rem;
    position: relative;
}

.divide h2 {
    font-size: 20px;
    font-weight: 400;
}





.lastCTA {
    width: 100%;
    height: 400px;
    margin: 0px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

}

.lastCTA h3 {
    margin-top: 0px;
}

.sparker {
    position: absolute;
    width: 40%;
    top: 12%;
}

#test {
    width: 380px;
    margin: 30px;
    height: auto;
}

.warning {
    visibility: hidden;
}

.tagline {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 85%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.tagline-left {
    display: flex;
    width: 40vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
}

.tagline-right {
    width: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
}


/*FOOTER ======================================*/

footer {
    padding: 0px;
    width: 100%;
    height: 150px;
    position: relative;
    bottom: 0px;
    background-color: var(--primary1);
    box-sizing: border-box;
}

.footer-1 {
    width: 80%;
    height: 100%;
    margin: auto;
    padding: 0;
}

.footer-title {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.footer-right {
    display: flex;
    padding: 2.5% 0px;
    width: 20%;
    height: 60%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0;
}

.footer-right p {
    padding: 0;
    margin: 0;
}

.sns-logo {
    padding: 0px;
    margin: 0px;
}

.footer-right a {
    text-decoration: none;
    color: var(--special-black);
    opacity: 1;
    transition: 0.1s ease-in-out;
    border-radius: 600px;
    margin-left: 20px;
}

.footer-right a:hover, .fa-brands:hover {
    color: var(--primary2-dark2);
    cursor: pointer;
    transition: 0.1s ease-in-out;
    opacity: 1;
}

.footer-content {
    width: 90%;
    display: flex;
    flex-direction: row;
    margin: 0px;
}

.footer-about {
    width: 50%;
    height: 100%;

}

.footer-signup {
    width: 50%;
    height: 100%;
}

.footer-signup h3 {
    width: 90%;
    margin: auto;
}

footer form {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}

.footer-text {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--special-black);
    text-decoration: none;

}

.footer-text a {
    font-size: 10px;
    text-decoration: none;
    color: var(--special-black);
}


/*MEDIA QUERY */



/*FOR MOBILE DEVICE*/
@media only screen and (max-width:600px){
  

    body {
        width: 100vw;
        box-sizing: border-box;
    }

  /*NAV BAR */

    .wepo-logo {
        margin: 0px;
        width: 3rem;
    }

    .nav {
        height: 40px;
        position: sticky;
        top: 0px;
        z-index: 100;
        padding: 0px 20px;
        display: flex;

    }

    .icon {
        font-size: 1.5rem;
        color: var(--special-black);
        display: unset;
    }

    .navRight {
        background: var(--special-black);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        box-sizing: border-box;
        width: 20px;
    }


  /*HERO CONTENT*/
  .heroContainer{
    all:unset;
    display: flex;
    justify-content: center;
    align-items: center;
    width:85vw;
    margin:auto;
    box-sizing: border-box;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column-reverse;
  }
  .heroRight{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
  }

  .heroRight img{
    all:unset;
    width:100%;
    position: relative;
    left: -40px;
  }
  .switch{
    box-sizing: border-box;
    position:relative;
    margin:2.5rem auto 0.5rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h2{
    font-size: 10px;
  }

  #end{
    width:90%;
    margin:auto;
    position: relative;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  .heroLeft{
    all:unset;
    width:100%;
  }

    .heroLeft p {
        text-align: justify;
    }


    .CTA-container{
        width:90%;
    }
    .CTA {
        width: 100%;
        height:50px;
        display: flex;
        align-items: center;
        align-content: space-between;

    }

    
  .footer{
    position:absolute;
    bottom: 0px;
    box-sizing: border-box;
  }
  .footer-right{
    width:100%;
    align-self: center;
    justify-self: center;
    align-items: center;
  }

  .lastCTA{
    height:fit-content;
    margin :3rem 0px;
  }
  .wepo-logo{
    display: none;
  }

  .sns-logo{
    gap:20px;
    
  }
  .sns-logo a{
    all:unset;
  }
  .sns-logo i{
    font-size: 24px;
  }
}

/*FOR TABLETTE*/
